import React, { useState, useEffect, useContext } from "react";
import { DFPSlotsProvider, AdSlot } from "react-dfp";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import AdhesionBanner from "../../atoms/AdhesionBanner/AdhesionBanner";
import PopUpBanner from "../../atoms/PopUpBanner/PopUpBanner";
import getData from "../../services/GetData";
import PaginationAgrofy from "../../atoms/PaginationAgrofy/PaginationAgrofy";
import SectionTemplate from "../../templates/sectionTemplate/SectionTemplate";
import { AgroPushNotification } from "../../components/AgroPushNotification/AgroPushNotification";
import withSSR from "../../withSSR";
import {
  gtmInitialize,
  manageGTMPageName,
  manageGTMUserStatus
} from "./../../utils/GTMDataLayerFuncs";
import UserContext from "../../atoms/UserContext/UserContext";

const AapresidConference = (props) => {
  const { t } = useTranslation();
  const serverData = props && props.serverData ? props.serverData : false;
  const [updatedData, setUpdatedData] = useState();
  const [seoTitle, setSeoTitle] = useState();
  const [seoText, setSeoText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const tagName =
    (serverData &&
      serverData.title_section &&
      serverData.title_section.replace(/^\w/, (c) => c.toUpperCase())) ||
    "";
  const amount = serverData && serverData.pages;

  const dfpPrefix = "agrofynews_especificos/aapresid";
  const dfpNetworkId1 = "21735159925";

  const templateBanners = [
    {
      adUnit: "aapresid_middle_1",
      sizes: [
        [728, 90],
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "aapresid_middle_2",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "aapresid_middle_3",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "aapresid_filmstrip",
      sizes: [[300, 600]],
    },
  ];

  const { mkpUserData, userLoading } = useContext(UserContext);

  const fetchData = () => {
    const url =
      process.env.RAZZLE_ENDPOINT_NOTI_API ||
      window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true);
    return getData({
      url: `${url}/api/News/GetNewsListByTag?tag=tags/congreso-aapresid&page=${
        currentPage - 1
      }&limit=15`,
    }).then((result) => {
      setUpdatedData(result.data);
      setSeoTitle(result && result.data && result.data.title_section);
      setSeoText(result && result.data && result.data.seoText);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    fetchData();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (updatedData) {
      const section = document.getElementById("section-template");
      fetchData();
      if (section) {
        document.getElementById("section-template").scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window && serverData) {
      manageGTMPageName(`Home ${serverData.title_section}`);
    }
  }, [serverData]);

  return (
    <div className="container px-0">
      <Helmet>
        <title>{`${tagName} | Encontrá todo sobre Aapresid en Agrofy News`}</title>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://news.agrofy.com.ar/expo/aapresid" />
        <meta
          name="description"
          content="Encontrá todo sobre el XXVIII Congreso Aapresid 2020, el evento de innovación técnica más grande del Agro argentino! Del 18 al 28 de agosto 100% virtual."
        />
      </Helmet>
      <AgroPushNotification location="Section" />
      <section className="dfp-banner stripped container top-banner">
        <DFPSlotsProvider dfpNetworkId={dfpNetworkId1}>
          <AdSlot
            adUnit={dfpPrefix + "_header"}
            sizes={[
              [970, 90],
              [970, 250],
              [320, 80],
            ]}
          />
        </DFPSlotsProvider>
      </section>
      <section id="main-section" className="d-flex flex-wrap mt-20">
        <div className="fluid-container col-lg-9 px-0">
          <section className="container">
            <h1>{seoTitle}</h1>
            <p>{seoText}</p>
            <SectionTemplate
              hasVideo={false}
              videoCategory="29509"
              data={updatedData || serverData}
              classes="small-spacing-top"
              bannersData={templateBanners}
              loading={isLoading}
              isExpo={true}
            />
            <div className="mb-36">
              {currentPage && amount && (
                <PaginationAgrofy
                  parentCallback={setCurrentPage}
                  currentPage={currentPage}
                  amount={amount}
                />
              )}
            </div>
          </section>
        </div>
        <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
          <div className="make-me-sticky">
            <DFPSlotsProvider dfpNetworkId={dfpNetworkId1}>
              <AdSlot adUnit={dfpPrefix + "_followme"} sizes={[[260, 600]]} />
            </DFPSlotsProvider>
          </div>
        </div>
      </section>
      <AdhesionBanner adUnit={dfpPrefix + "_adhesion"} sizes={[[320, 80]]} specialNetworkID={dfpNetworkId1}/>
      <PopUpBanner adUnit={dfpPrefix + "_fullscreen"} sizes={[[800, 600],[320, 480]]} specialNetworkID={dfpNetworkId1}/>
    </div>
  );
};

AapresidConference.getInitialProps = async ({ match, req, res }) => {
  const url =
    process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  try {
    const { data } = await getData({
      url: `${url}/api/News/GetNewsListByTag?tag=tags/congreso-aapresid&page=0&limit=15`,
    });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(AapresidConference);
